//import { createStore } from "vuex";
import { reactive, readonly } from "vue";

const state = reactive({
  activeWall: "gross",
  activeWebsocket: new Array(),
  activeWettbewerbe: new Array(
    {
      wett: 1,
      name: "ULTRATRAIL",
      balken: "#298D2D",
      verlauf: "rgb(41, 141, 45)",
    },
    { wett: 2, name: "EHRWALD TRAIL", balken: "#7EB348" },
    { wett: 3, name: "LEUTASCH TRAIL", balken: "#007EC7" },
    { wett: 4, name: "MITTENWALD TRAIL", balken: "#29BDEF" },
    {
      wett: 5,
      name: "GARMISCH-PARTENKIRCHEN TRAIL",
      balken: "#556D78",
      verlauf: "85,109,120",
    },
    {
      wett: 6,
      name: "GRAINAU TRAIL",
      balken: "#ADA8A5",
    }
    //    {
    //      wett: 1,
    //      name: "1st stage — Lech am Arlberg 🇦🇹 - St. Anton am Arlberg 🇦🇹",
    //      balken: "#FFAA21",
    //      verlauf: "rgb(41, 141, 45)",
    //    },
    //    {
    //      wett: 4,
    //      name: "4th stage — Galtür 🇦🇹 - Klosters 🇨🇭",
    //      balken: "#FFAA21",
    //      verlauf: "rgb(41, 141, 45)",
    //    },
    //    {
    //      wett: 5,
    //      name: "5th stage — Bergsprint - Scuol 🇨🇭",
    //      balken: "#FFAA21",
    //      verlauf: "rgb(41, 141, 45)",
    //    },
    //    {
    //      wett: 6,
    //      name: "6th stage — Scuol 🇨🇭 - St. Valentin auf der Haide 🇮🇹",
    //      balken: "#FFAA21",
    //      verlauf: "rgb(41, 141, 45)",
    //    },
    //    {
    //      wett: 7,
    //      name: "7th stage — St. Valentin auf der Haide - Prad am Stilfserjoch",
    //      balken: "#FFAA21",
    //      verlauf: "rgb(41, 141, 45)",
    //    },
    //    {
    //      wett: 11,
    //      name: "RUN2 1st stage — Lech am Arlberg 🇦🇹 - St. Anton am Arlberg 🇦🇹",
    //      balken: "#B7B7B7",
    //    },
    //    {
    //      wett: 12,
    //      name: "RUN2 2nd stage — St. Anton am Arlberg 🇦🇹- Ischgl 🇦🇹",
    //      balken: "#B7B7B7",
    //    },
    //    {
    //      wett: 21,
    //      name: "SOLO 1st stage — Lech am Arlberg 🇦🇹 - St. Anton am Arlberg 🇦🇹",
    //      balken: "#160C02",
    //    },
    //    {
    //      wett: 24,
    //      name: "4th stage — Galtür 🇦🇹 - Klosters 🇨🇭",
    //      balken: "#160C02",
    //    },
    //    {
    //      wett: 25,
    //      name: "5th stage — Bergsprint - Scuol 🇨🇭",
    //      balken: "#160C02",
    //    },
    //    {
    //      wett: 26,
    //      name: "6th stage — Scuol 🇨🇭 - St. Valentin auf der Haide 🇮🇹",
    //      balken: "#160C02",
    //    },
    //    {
    //      wett: 27,
    //      name: "7th stage — St. Valentin auf der Haide - Prad am Stilfserjoch",
    //      balken: "#160C02",
    //    }
  ),
  activeSelectedWettbewerbe: new Array(1),
  activeSelectedWettbewerb: 1,
  activeSelectedWettbewerbTyp: new Array(
    {
      variable: "Geschlecht",
      filter: "M",
      name: "TOP 3 MEN",
      value: 1,
      label: "Geschecht M",
    },
    {
      variable: "Geschlecht",
      filter: "W",
      name: "TOP 3 WOMAN",
      value: 2,
      label: "Geschecht W",
    }
  ),
  activeSelectedWettbewerbTypSelected: null,
});
const getters = {
  activeWall: () => state.activeWall,
  activeWebsocket: () => state.activeWebsocket,
  activeWettbewerbe: () => state.activeWettbewerbe,
  activeSelectedWettbewerbe: () => state.activeSelectedWettbewerbe,
  activeSelectedWettbewerb: () => state.activeSelectedWettbewerb,
  activeSelectedWettbewerbTyp: () => state.activeSelectedWettbewerbTyp,
  activeSelectedWettbewerbTypSelected: () =>
    state.activeSelectedWettbewerbTypSelected,
};
const mutations = {
  setWall(view) {
    console.log("STATE: setWall", view);
    state.activeWall = view;
  },
  setWebsocket(view) {
    console.log("STATE: setWebsocket", view);
    state.activeWebsocket = view;
  },
  setSelectedWettbewerbe(view) {
    console.log("STATE: setSelectedWettbewerbe", view);
    state.activeSelectedWettbewerbe = view;
  },
  setSelectedWettbewerb(view) {
    console.log("STATE: setSelectedWettbewerb", view);
    state.activeSelectedWettbewerb = view;
  },
  setSelectedWettbewerbTyp(view) {
    console.log("STATE: setSelectedWettbewerbTyp", view);
    state.activeSelectedWettbewerbTyp = view;
  },
  setSelectedWettbewerbTypSelected(view) {
    console.log("STATE: setSelectedWettbewerbTypSelected", view);
    state.activeSelectedWettbewerbTypSelected = view;
  },
};

export default {
  state: readonly(state),
  getters,
  mutations,
  actions: {},
  modules: {},
};
