import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/screen/1",
    name: "screen_1",
    // route level code-splitting
    // this generates a separate chunk (TheScreen.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "TheScreen1" */ "../views/TheScreen1.vue"),
  },
  {
    path: "/screen/2",
    name: "screen_2",
    // route level code-splitting
    // this generates a separate chunk (TheList.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "TheScreen2" */ "../views/TheScreen2.vue"),
  },
  {
    path: "/screen/3",
    name: "screen_3",
    // route level code-splitting
    // this generates a separate chunk (TheList.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "TheScreen3" */ "../views/TheScreen3.vue"),
  },
  {
    path: "/screen/4",
    name: "screen_4",
    // route level code-splitting
    // this generates a separate chunk (TheList.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "TheScreen4" */ "../views/TheScreen4.vue"),
  },
  {
    path: "/screen/5",
    name: "screen_5",
    // route level code-splitting
    // this generates a separate chunk (TheList.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "TheScreen5" */ "../views/TheScreen5.vue"),
  },
  {
    path: "/screen/6",
    name: "screen_6",
    // route level code-splitting
    // this generates a separate chunk (TheList.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "TheScreen6" */ "../views/TheScreen6.vue"),
  },
  {
    path: "/screen/7",
    name: "screen_7",
    // route level code-splitting
    // this generates a separate chunk (TheList.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "TheScreen7" */ "../views/TheScreen7.vue"),
  },
  {
    path: "/screen/8",
    name: "screen_8",
    // route level code-splitting
    // this generates a separate chunk (TheList.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "TheScreen8" */ "../views/TheScreen8.vue"),
  },
  {
    path: "/screen/9",
    name: "screen_9",
    // route level code-splitting
    // this generates a separate chunk (TheList.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "TheScreen8" */ "../views/TheScreen9.vue"),
  },
  {
    path: "/screen/10",
    name: "screen_10",
    // route level code-splitting
    // this generates a separate chunk (TheList.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "TheScreen8" */ "../views/TheScreen10.vue"),
  },
  {
    path: "/screen/21",
    name: "screen_21",
    // route level code-splitting
    // this generates a separate chunk (TheList.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "TheScreen8" */ "../views/TheScreen21.vue"),
  },
  {
    path: "/screen/22",
    name: "screen_22",
    // route level code-splitting
    // this generates a separate chunk (TheList.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "TheScreen8" */ "../views/TheScreen22.vue"),
  },
  {
    path: "/screen/23",
    name: "screen_23",
    // route level code-splitting
    // this generates a separate chunk (TheList.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "TheScreen8" */ "../views/TheScreen23.vue"),
  },
  {
    path: "/screen/24",
    name: "screen_24",
    // route level code-splitting
    // this generates a separate chunk (TheList.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "TheScreen8" */ "../views/TheScreen24.vue"),
  },
  {
    path: "/screen/31",
    name: "screen_31",
    // route level code-splitting
    // this generates a separate chunk (TheList.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "TheScreen8" */ "../views/TheScreen31.vue"),
  },
  {
    path: "/screen/32",
    name: "screen_32",
    // route level code-splitting
    // this generates a separate chunk (TheList.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "TheScreen8" */ "../views/TheScreen32.vue"),
  },
  {
    path: "/screen/33",
    name: "screen_33",
    // route level code-splitting
    // this generates a separate chunk (TheList.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "TheScreen8" */ "../views/TheScreen33.vue"),
  },
  {
    path: "/screen/34",
    name: "screen_34",
    // route level code-splitting
    // this generates a separate chunk (TheList.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "TheScreen8" */ "../views/TheScreen34.vue"),
  },
  {
    path: "/screen/35",
    name: "screen_35",
    // route level code-splitting
    // this generates a separate chunk (TheList.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "TheScreen8" */ "../views/TheScreen35.vue"),
  },
  {
    path: "/screen/36",
    name: "screen_36",
    // route level code-splitting
    // this generates a separate chunk (TheList.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "TheScreen8" */ "../views/TheScreen36.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
