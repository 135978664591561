<template>
  <div class="home">
    Uhrenkonfig
    {{ wett_array }}
    <div v-if="wett_array">
      <div v-for="eintrag in wett_array" :key="eintrag.wett">
        <UhrKonfig :id="eintrag.wett" @balken-updated="modusStatusBalken" />
      </div>
    </div>
    <div>
      Wall-Größe: {{ wall_groesse }}
      <button @click="updateWallGroesse">ändern</button>
    </div>
    <div v-if="!wett_array">Daten laden...</div>
  </div>
</template>

<script>
// @ is an alias to /src
import UhrKonfig from "@/components/UhrKonfig.vue";
import Store from "@/store/index";

export default {
  name: "HomeView",
  components: {
    UhrKonfig,
  },
  computed: {
    wall_groesse() {
      return Store.getters.activeWall();
    },
    wett_array() {
      return Store.getters.activeWettbewerbe();
    },
  },
  methods: {
    wett_name(wettid) {
      if (this.wett_array) {
        let temp = this.wett_array;

        temp.filter(function (element) {
          return element.wett == wettid;
        });
        return false;
      }
      return false;
    },
    // Emit Balken Status
    updateWallGroesse() {
      console.log("ändern", this.wall_groesse);
      if (this.wall_groesse == "klein") {
        Store.mutations.setWall("gross");
        this.$emit("wall-updated", "gross");
      } else {
        Store.mutations.setWall("klein");
        this.$emit("wall-updated", "klein");
      }
    },
    // Emit Balken Status
    modusStatusBalken(modusDO) {
      console.log("modusStatusBalken: ", modusDO);
      //this.play();
      //alert(modusDO.newModus);
      this.$emit("balken-updated", { newModus: modusDO });
      //this.setUhrWettbewerb(modusDO.newModus);
    },
  },
  emits: {
    "balken-updated": (modusDO) => {
      if ("newModus" in modusDO === false) {
        console.warn(
          "ModusCardComponent: Jede Button muss ein Balken-Attribut haben."
        );
        return false;
      }
      return true;
    },
  },
};
</script>
