<template>
  <div class="hello" v-if="wett_name">
    {{ wett_name(id).name }}
    <input
      v-if="websocket.countdownzeit"
      type="text"
      name="text"
      :id="inputid"
      :value="websocket.countdownzeit[id]"
    />
    <!--<button v - on="sendText(id)">Countdownupdate</button>-->

    <div class="color">
      <input
        type="text"
        v-bind:style="{ color: color }"
        v-model="color"
        maxlength="7"
        placeholder="#000000"
      />
      <input type="color" v-model="color" />
      <!--<button v - on="updateBalken(id)">BalkenUpdate</button>-->
    </div>

    <br />
  </div>
</template>

<script>
import Store from "@/store/index";

export default {
  name: "UhrKonfig",
  data() {
    return {
      inputid: null,
      color: "#000001",
    };
  },
  props: {
    id: Number,
  },
  mounted() {
    this.inputid = "cd" + Number(this.id);
  },
  computed: {
    websocket() {
      return Store.getters.activeWebsocket();
    },
    wett_array() {
      return Store.getters.activeWettbewerbe();
    },
  },

  methods: {
    wett_name(wettid) {
      if (this.wett_array) {
        let temp = this.wett_array;

        temp = temp.filter(function (element) {
          let daten = element.wett == wettid;
          //console.log("balken", daten);
          //this.color = "#" + daten.balken;
          return daten;
        });
        if (this.color == "#000001") {
          this.color = temp[0].balken;
        }
        return temp[0];
      }
      return "";
    },
    updateBalken(color) {
      this.$emit("balken-updated", { newModus: color });
    },
    sendText(color) {
      console.log("sendText", color);
      //this.$emit("balken-updated", { newModus: color });
    },
  },
  emits: {
    "balken-updated": (modusDO) => {
      if ("newModus" in modusDO === false) {
        console.warn(
          "ModusCardComponent: Jede Button muss ein Balken-Attribut haben."
        );
        return false;
      }
      return true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
